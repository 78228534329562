document.addEventListener("DOMContentLoaded", function() {
  const flash = document.querySelector('.close-flash')
  if (flash) {
    const notice_box = flash.closest('.flash')

    flash.addEventListener('click', event => {
      notice_box.classList.add('hidden')
    })
  }
})
